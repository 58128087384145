@import "variables";
@import "mixins";
@import "colors";
@import "~bootstrap/scss/mixins/breakpoints";

.sliderContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
    justify-content: center;
    color: var(--text-inverse);
    picture {
        z-index: -1;
        position: absolute;
        @include fadeIn(1s);
        &,
        img {
            width: auto;
            object-fit: unset !important;
        }
    }
}

.sliderTitle {
    margin: 12.5rem 0;
}

.sliderCarouselWrapper {
    @include media-breakpoint-up("lg") {
        width: 50rem;
        padding-right: 1rem;
    }
    margin: 0 -1rem;
}

.sliderCarousel {
}

@include media-breakpoint-down("md") {
    .sliderCarouselWrapper {
        margin: 0 -2rem;
        &::after,
        &::before {
            content: "";
            z-index: 1;
            width: 2rem;
            height: 100%;
            position: absolute;
            top: 0;
        }
        &::after {
            right: 0;
            background: linear-gradient(to right, transparent, #2f2f3a);
        }
        &::before {
            left: 0;
            background: linear-gradient(to left, transparent, #2f2f3a);
        }
    }
    .sliderCarousel {
        margin: 0 1rem;
    }
}

.sliderSlide {
    padding: 0 1rem;
    position: relative;
    img {
        width: 18rem;
        height: 12rem;
        cursor: pointer;
        object-fit: cover;
        @include transition();
        // pointer-events: none;
        border-radius: $border-radius;
    }
}

// .sliderSlide[visible="true"]::after {
.sliderActiveSlide::after {
    content: "\e901";
    font-family: "ticlix";
    width: 2.8rem;
    font-size: 2.8rem;
    line-height: 2.8rem;
    margin: auto;
    cursor: pointer;
    position: absolute;
    border-radius: 100%;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    background-color: var(--text-color);
}

.sliderPrev,
.sliderNext {
    opacity: 0.8;
    --width: 3.4rem;
    --color: #{$white};
}
.sliderNext {
    transform: rotate(180deg);
    // margin-inline-start: 3rem;
}
