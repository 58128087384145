@use "sass:selector";

@mixin transition($time: 200ms) {
  // -webkit-transition: all $time cubic-bezier(0.445, 0.05, 0.55, 0.95);
  // -moz-transition: all $time cubic-bezier(0.445, 0.05, 0.55, 0.95);
  // -ms-transition: all $time cubic-bezier(0.445, 0.05, 0.55, 0.95);
  // -o-transition: all $time cubic-bezier(0.445, 0.05, 0.55, 0.95);
  // transition: all $time cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-transition: all $time linear;
  -moz-transition: all $time linear;
  -ms-transition: all $time linear;
  -o-transition: all $time linear;
  transition: all $time linear;
}

@mixin advance-transition($transition) {
  -webkit-transition: #{$transition};
  -moz-transition: #{$transition};
  -ms-transition: #{$transition};
  -o-transition: #{$transition};
  transition: #{$transition};
}

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $color;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color;
  }
}

@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--border-color);
  }
}

@mixin nonselect() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin selectable {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

@mixin unify-parent($child) {
  @at-root #{selector.unify(&, $child)} {
    @content;
  }
}

@mixin fadeIn($delay: 700ms) {
  animation: fadein $delay;
  -moz-animation: fadein $delay;
  -webkit-animation: fadein $delay;
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  @-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
}
@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
      color: $color;
  }
  &:-moz-placeholder {
      color: $color;
  }
  &::-moz-placeholder {
      color: $color;
  }
  &:-ms-input-placeholder {
      color: $color;
  }
}
